<template>
   <v-row justify="center">
    <v-col cols="12" sm="10" md="8" lg="6">
      <v-card ref="form">
        <v-card-title> Add New Remedies </v-card-title>
        <v-card-text>
                  <v-text-field
                    v-model="name"
                    label="Remedy Name"
                    required
                  ></v-text-field>
                   <v-text-field
                    v-model="comment"
                    label="Comment"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="quantityGiven"
                    label="Quantity Given"
                    type="number"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="witness"
                    label="Witness"
                    required
                  ></v-text-field>
                  <v-btn
                    color="warning"
                    @click="register"
                  >
                    Submit
                  </v-btn>
        </v-card-text>
      </v-card>
    </v-col>
   </v-row>
</template>

<script>
import {mapGetters, mapActions } from 'vuex'
export default {
    name:'registerRemedy',
    data () {
      return {
        name: '',
        comment: '',
        quantityGiven: '',
        resident: '',
        user: '',
        witness: ''
      }
    },
    computed: {
        ...mapGetters([
           'getLoggedUser'
        ]),
    },
     created() {
       this.getLoggedInUser();
    },
    methods: {
      ...mapActions([
          'register_remedy',
          'getLoggedInUser',
      ]),
      register () {
        this.user = this.getLoggedUser.user.id;
        this.resident = this.$route.params.residentId;
        this.register_remedy({
          ...this.$data
        })
        /* eslint-disable */
        .then(response => {
            this.$router.push('/remedies')
        })
        .catch(error => {
            console.log(error)
        })
      },
    },
}
</script>

<style lang="stylus" scoped>

</style>